@import './mixins.scss';

.test-counter{
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2{
    font:400 20px $fontFamily;
  }

  .test-section{
    p{
        color:$neutralLight;
    }

    .test-num{
      font:700 $fontLarge $fontFamily;
      margin:20px 0 0;

      &.planning{
        color:$orangeMisc;
      }

      &.running{
        color:$tealDark;
      }

      &.hosted{
        color:$pinkDark;
      }

      &.to-date{
        color:$purpleMid;
      }
    }
  }

  @include breakpoint('mobile') {
    flex-wrap: wrap;

    h2{
      width:100%;
    }

    .test-section{
      margin:20px 0 0;
      min-width: 115px;

      .test-num{
        margin:5px 0 0;
      }
    }
  }
}