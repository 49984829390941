@import './mixins.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
  position: fixed;
  width: 100%;
  top: 0;
  right: 0;
  height: 70px;
  background-color: $white;
  box-shadow: $box-shadow;

  @include breakpoint('mobile') {
    padding: 0 15px;
    justify-content: space-between;
  }

  .hamburger {
    display: none;
    height: 20px;
    width: 25px;

    @include breakpoint('mobile') {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    span {
      width: 100%;
      height: 3px;
      background-color: $neutralDark;
      border-radius: 20px;
    }
  }

  .header-logo {
    display: none;
    background: url('../img/trinity-branding/trinity-dark@2x.png') no-repeat;
    width: 140px;
    height: 32px;
    background-size: contain;

    @include breakpoint('mobile') {
      display: block;
    }
  }

  .user-content {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;

    &:hover {
      .dropdown-content {
        display: block;
      }

      .user:after {
        transform: rotate(225deg);
      }
    }

    .user {
      font-size: $fontSmall;
      color: $neutralDark;

      &:after {
        content: '';
        border: solid $neutralLight;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        margin: 0 15px 0 10px;
        transition: .15s;
      }


      @include breakpoint('mobile') {
        display: none;
      }
    }

    .user-img {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      margin:0 10px;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      min-width: 160px;
      box-shadow: $box-shadow;
      z-index: 1;
      top: 100%;
      right: 40px;

      @include breakpoint('mobile') {
        right: 15px;
      }

      a {
        font-size: $fontSmall;
        color: $neutralDark;
        padding: 12px 16px;
        text-decoration: none;
        display: block;

        &:hover {
          background-color: $neutralLightest;
        }
      }
    }
  }
}
