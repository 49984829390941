@import './mixins.scss';

.update-photo {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;

  .curtain {
    background: rgba(0, 0, 0, .6);
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
  }

  .update-photo-modal {
    position: absolute;
    background-color: $white;
    top: 33%;
    left: 33%;
    width: 33%;
    height: 350px;
    z-index: 100;

    .update-photo-headline {
      width: 100%;
      padding-top: 1em;
      text-align: center;
    }

    .update-photo-submit {
      @include orangeButton;
    }

    .update-photo-cancel {
      @include hollowOrangeButton;
    }
  }

  .update-photo-header {
    background-color: $neutralLight;
    color: white;
    height: 4em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 30px;

    .update-photo-title {
      color: white;
      font:bold 18px $fontFamily;
    }
  }
}

.update-photo-body {
  height: 50%;
}

.action-buttons {
  padding-top: 2em;
  text-align: center;
}

.update-photo-cloud {
  //top: 33%;
  //left: 33%;
  width: 33%;
  height: 33%;
  margin-left: 33%;
  margin-top: 2em;
}

.drop {
  height: 10em;
  vertical-align: center;
  text-align: center;
  background-color: $neutralLightest;
}

.photo-crop {
  height: 50%;
}
