@import './mixins.scss';

.calendar-view{
  .calendar{
    .weekdays{
      .day{
        padding:10px 0 20px;
      }
    }
  
    .week{
      .day{
        min-height: 150px;
      }
    }
  
    .day{
      padding:10px 0;
    }
  }
}