@import './mixins.scss';

/* Progress bar */
.progress-container {
  display: inline-block;
  position: relative;
  width: 100%;
  text-align: center;

  .percentage {
    font-size: $fontExtraSmall;
    margin: 0 0 5px;
  }

  .progress_bar {
    background: $warmGradient;
    height: 5px;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }

  .progress {
    height: 5px;
    position: absolute;
    right: 0;
    width: 20%;
    background-color: #EDF1F1;
    background-size: 100%;
  }
}
