@import './mixins.scss';

.login {
  height: 100vh;
  width:100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:30px 0;
  color: $neutralDark;

  .login-content {
    height:calc(100vh - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0 auto;
    font: 400 $fontRegular 'Montserrat';

    .back {
      margin: 0 auto 20px;
      cursor: pointer;
      text-transform: uppercase;
    }

    .logo {
      background: url('../img/trinity-branding/trinity-dark@2x.png') no-repeat;
      height: 74px;
      width: 319px;
      background-size: contain;
    }

    h3 {
      margin: 35px auto 45px;
    }

    input[name=password],
    input[name=email] {
      border: 1px solid $neutralLight;
      background-color: rgba(255,255,255,.8);
      color: #4D565C;
      max-width: 400px;
      width: 100%;
      margin: 0 0 30px;
      padding: 10px;

      &::placeholder {
        color: #4D565C;
      }

      &:focus {
        outline: none;
      }
    }

    .login-assistance {
      max-width: 400px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 20px 65px;
      padding:0 20px;

      .remember {
        cursor: pointer;

        input[type="checkbox"] {
          width: 18px;
          height: 18px;
          margin: 0 10px 2px 0;
          vertical-align: middle;
        }

        &:before {
          content: '';
          display: inline-block;
          vertical-align: middle;
          border: 1px solid $neutralDark;
          border-radius: 4px;
          background: $white;
          width: 17px;
          height: 17px;
          position: absolute;
        }

        &.checked:before {
          background: url('../img/CheckBoxSelected.svg') no-repeat;
          background-position: center;
        }
      }

      .forgot{
        cursor: pointer;
      }
    }

    button {
      @include orangeButton;
    }
  }

  .terms-of-use {
    font-size: $fontExtraSmall;
    cursor: pointer;
    transition: .15s;

    span:hover {
      text-decoration: underline;
    }
  }

  @include breakpoint('mobile') {
    .login-content {
      h3 {
        font-size: $fontRegular;
      }

      .login-assistance {
        padding:0
      }
    }
  }
}
