@import './mixins.scss';

html, * {
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: $fontFamily;
  overflow: hidden;
  display: flex;
}

// Font  =============================
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

h1 {
  font:400 28px $fontFamily;
  color: $neutralDark;
  margin: 0 0 20px;

  @include breakpoint('mobile') {
    margin: 0 10px 20px;
  }
}

h3 {
  font:400 18px $fontFamily;
  color: $neutralDark;
}

// View all/Show more link =================
.view-all-link {
  color: $blueMid;
  text-decoration: none;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  transition: .15s;
  display: block;

  &:hover {
    text-decoration: underline;
    color: #E0740B;
  }
}

//Grid slash flex system ala Kate  =========
.view {
  width: calc(100vw - 240px);
  height: calc(100vh - 70px);
  padding: 30px 65px;
  margin: 70px 0 0 240px;
  overflow-y: auto;
  transition: margin .25s;
  background-color: #F6F9F9;

  @include breakpoint('tablet') {
    width: calc(100vw - 70px);
    margin: 70px 0 0 70px;
  }

  @include breakpoint('mobile') {
    width: 100vw;
    padding: 25px 0 40px;
    margin: 70px 0 0;
  }

  .view-content {
    margin:0 -10px;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint('mobile') {
      margin: 0;
    }
  }

  .card {
    margin: 10px;
    width:100%;
    height: 100%;
    background-color: $white;
  
    .card-content {
      box-shadow: $box-shadow;
      padding: 20px;
    }

    &.one-quarter{
      width:calc(25% - 20px);

      @include breakpoint('mobile') {
        width:100%;
      }
    }

    &.three-quarters{
      width:calc(75% - 20px);

      @include breakpoint('mobile') {
        width:100%;
      }
    }
  }
}

//Collapsed ================================
.collapsed .view {
  width: calc(100vw - 70px);
  margin: 70px 0 0 70px;

  @include breakpoint('mobile') {
    width: 100vw;
    padding: 25px 0 40px;
    margin: 70px 0 0;
  }
}
