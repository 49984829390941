@import './mixins.scss';

.visualizer.view {
  .visualizer-header {
    background-color: $blueMid;
    width: 100%;
    margin: 0 15px 5px;
    text-align: center;
    padding: 15px;
    border-radius: 4px;
    text-transform: uppercase;
    color: $white;
    font-weight: 700;
    font-size: $fontSmall;
  }

  .card {
    margin: 5px 15px;
    max-width: calc(100% - 30px);

    .card-content {
      border-radius: 3px;
    }
  }
}
