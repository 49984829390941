@import './mixins.scss';

// ============================================
// Remove display:none on .relevant-docs for V2


.visualizer-row {
  .visualizer-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $neutralDark;
    font-size: $fontSmall;

    div {
      width: 33%;

      &.row-toggle {
        border: 1px solid #013847;
        max-width: 105px;
        padding: 8px 10px;
        border-radius: 5px;
        cursor: pointer;

        &:before {
          content: 'Explore';
        }

        &:after {
          content: '';
          border: solid #013847;
          border-width: 0 2px 2px 0;
          padding: 3px;
          transform: rotate(45deg);
          transition: .15s;
          margin: 3px 0 0;
          float: right;
        }
      }
    }

    .visualizer-title {
      font-size: 18px;

      &:before {
        width: 20px;
        height: 20px;
        content: '';
        display: inline-block;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin: 0 15px 2px 0;
      }

      &.global-elements:before {
        background-image: url('../img/visualizer-icons/Global.svg');
      }

      &.home-page:before {
        background-image: url('../img/visualizer-icons/Homepage.svg');
      }

      &.product-listing-page:before {
        background-image: url('../img/visualizer-icons/ProductListing.svg');
      }

      &.add-to-cart-modal:before {
        background-image: url('../img/visualizer-icons/AddtoCart.svg');
      }

      &.cart-page:before {
        background-image: url('../img/visualizer-icons/Cart.svg');
      }

      &.checkout-page:before {
        background-image: url('../img/visualizer-icons/Checkout.svg');
      }

      &.account-login:before {
        background-image: url('../img/visualizer-icons/Account.svg');
      }

      &.sales-page:before {
        background-image: url('../img/visualizer-icons/Sales.svg');
      }
    }

    @include breakpoint('mobile') {
      div {
        width: 100%;

        &.row-toggle {
          border: 0;
          max-width: 20px;
          padding: 8px 10px;
          border-radius: 5px;

          &:before {
            content: '';
          }
        }
      }

      .visualizer-updates {
        display: none;
      }
    }
  }

  .dropdown-content {
    display: none;

    .relevant-docs {
      border: 1px solid $blueDark;
      display: inline-block;
      padding: 8px 10px;
      border-radius: 5px;
      margin: 20px 0 0;
      cursor: pointer;
      display: none;

      &:after {
        content: '';
        border: solid $neutralLight;
        border-width: 0 2px 2px 0;
        padding: 3px;
        transform: rotate(45deg);
        transition: .15s;
        margin: 5px 0 0 50px;
        float: right;
      }

      @include breakpoint('mobile') {
        width: 100%;
      }
    }

    .template-row::-webkit-scrollbar {
      display: none;
    }

    .template-row {
      margin: 40px 0;
      display: flex;
      overflow: scroll;
      overflow-y: hidden;
      -ms-overflow-style: none;

      .template {
        margin-right: 20px;
        text-align: center;

        .visualizer-image {
          background: $yellowMid;
          width: 250px;
          height: 250px;
          display: block;
          position: relative;
          cursor: pointer;

          p {
            position: absolute;
            font-size: 18px;
            padding: 0 20px;
            bottom: 20px;
            color: $white;
            width: 100%;
            text-align: center;
          }
        }

        .date {
          font-size: 14px;
          color: $neutralLight;
          margin: 10px 0 0;
        }
      }
    }
  }

  &.open {
    .visualizer-info {
      div {
        &.row-toggle {
          &:before {
            content: 'Collapse';
          }

          @include breakpoint('mobile') {
            &:before {
              content: '';
            }
          }

          &:after {
            transform: rotate(225deg);
            margin-top: 6px;

          }
        }
      }
    }

    .dropdown-content {
      display: block;
    }
  }
}
