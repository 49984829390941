.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
.modal-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: block;
  margin: auto;
  background: #000;
  opacity: 0.2;
  z-index: 50;
}
.modal-container .modal {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  display: block;
  margin: auto;
  min-height: 400px;
  min-width: 400px;
  max-width: 800px;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 99;
  padding: 0;  
}
.modal .modal-header {
  position: relative;
  background: #ADC1CA;
  h2 {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    // text-align: center;
    text-transform: capitalize;
    margin: 0;
    padding: 21px 30px 19px;
  }
  span {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #fff;
    font-weight: bold;

    &:hover {
      cursor: pointer;
    }
  }
}
.modal .modal-body {
  padding: 0 25px 25px;

  .input-section  {
    margin: 5px 5px 15px;

    label {
      display: block;
    }
    input, textarea, select {
      width: 100%;
    }
    input[type="number"] {
      width: 50%;
    }
  }
}
.modal .modal-footer {
  a {
    display: inline-block;
    width: calc(50% - 40px);
    margin: 20px;
    text-align: center;
    padding: 16px 0 15px;
    font-size: 15px;

    &:first-child {
      border: 2px solid #F77C30;
      color: #F77C30;

    }
    &:last-child {
      border: 2px solid #F77C30;
      background: #F77C30;
      color: #fff;

      
    }

    &:hover {
      cursor: pointer;
      font-weight: bold;
    }
  }
}