@import './mixins.scss';

.table::-webkit-scrollbar {
  display: none;
}

.table {
  font-size: 12px;
  width: 100%;
  border-collapse: collapse;
  overflow: scroll;
  -ms-overflow-style: none;

  .table-contents {
    display: table;
    width: 100%;
  }

  .table-row {
    display: table-row;
    border-bottom: 1px solid #97B1BA1A;
  }

  .table-head {
    background-color: #97B1BA1A;
    text-transform: uppercase;
    color: $neutralLight;
    font-weight: bold;
    border-bottom: transparent;

    .sortable {
      cursor: pointer;
    }
  }

  .table-cell {
    padding: 20px 15px;
    display: table-cell;
    vertical-align: middle;
    position: relative;

    a {
      color: $blueMid;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      i {
        margin-left: 5px;
        display: inline;
      }
    }

    &.active {
      &:after {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 5px;
        top: 50%;
        transform: translate(0, -50%);
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid $neutralLight;
      }

      &.reversed {
        &:after {
          border-top: 0;
          border-bottom: 5px solid $neutralLight;
        }
      }
    }
  }

  .table-image {
    max-width: 10vw;
  }

  .test-status {
    display: block;
    width: 30px;
    height: 30px;
    font-size: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
    position: relative;
    cursor: pointer;

    &:hover::after {
      margin: 0 auto;
      font-size: 10px;
      display: block;
      position: absolute;
      text-align: center;
      background:$white;
      box-shadow: $box-shadow;
      padding:6px 10px;
      border-radius: 4px;
      top:-20px;
      right:-40px;
    }

    &.active-test {
      background-image: url('../img/test-status-icons/RunningTest.svg');
      
      &:hover::after{
        content:'Active';
      }
    }

    &.hosted-test {
      background-image: url('../img/test-status-icons/HostedTest.svg');

      &:hover::after{
        content:'Hosted';
      }
    }

    &.paused-test {
      background-image: url('../img/test-status-icons/PausedTest.svg');

      &:hover::after{
        content:'Paused';
      }
    }

    &.past-test {
      background-image: url('../img/test-status-icons/PastTest.svg');

      &:hover::after{
        content:'Past';
      }
    }
  }
}
