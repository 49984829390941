@import './mixins.scss';

$used: #3C376A;
$remaining: #B6B1D8;

.hours-data {
  h3 {
    margin: 0 0 15px;
  }

  .hours-info {
    background-color: #97B1BA26;
    margin: 0 -20px;
    font-size: $fontRegular;
    color: $neutralLight;
    padding: 0 20px;

    div {
      padding: 10px 0;
      display: flex;
      justify-content: space-between;

      &.hours-carried-over {
        padding: 0 0 10px;
        border-bottom: 1px solid $neutralLightest;
      }

      &.hours-total {
        color: $neutralDark;

        span {
          color: $tealDark;
        }
      }
    }

    span {
      font-weight: bold;
    }
  }

  .hours-chart {
    max-width: 385px;
    margin: 0 auto;

    .hours-pie {
      position: relative;

      .ct-chart {
        margin: 35px auto;
        width: 145px;
        height: 145px;
      }

      .hours-used {
        path {
          fill: $used;
        }
      }

      .hours-remaining {
        path {
          fill: $remaining;
        }
      }

      .percentage {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        span {
          display: block;
        }
      }
    }

    .key {
      display: flex;
      justify-content: space-between;
      padding: 0 0 35px;

      .key-color {
        position: relative;
        padding-left: 20px;

        &:before {
          content: '';
          width: 10px;
          height: 10px;
          display: inline-block;
          border-radius: 50%;
          margin-right: 10px;
          position: absolute;
          left: 0;
          top: 5px;
        }
      }

      .remaining:before {
        background-color: $remaining;
      }

      .used:before {
        background-color: $used;
      }

      .hour-value {
        color: $neutralLight;
        font-size: $fontSmall;
        margin: 10px 0 0 20px;
      }
    }
  }
}
