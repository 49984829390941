@import './mixins.scss';

.contact.view {
  h1 {
    font-size: $fontExtraLarge;
    font-weight: bold;
    text-align: center;

    @include breakpoint('mobile') {
      font-size: 28px;
    }
  }

  h3 {
    color: $neutralLight;
    text-align: center;
    font-weight: 400;
    margin: 0 0 40px;

    @include breakpoint('mobile') {
      margin: 0 0 20px;
      padding: 0 30px;
    }
  }

  h4 {
    font-size: 24px;
    color: $neutralDark;
    padding: 20px 20px 0;

    @include breakpoint('mobile') {
      font-size: 20px;
    }
  }

  .view-content {
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    .card {
      position: relative;
      z-index: 1;
      width: auto;
      margin-right: -10%;

      @include breakpoint('tablet') {
        margin: 0;
        z-index: 0;
      }

      .contact-analyst-container {
        display: flex;
        padding: 35px 20px;
        border-bottom: 1px solid #00000029;

        .contact-img {
          width: 100px;
          height: 100px;
          // border-radius: 50%;
          margin-right: 20px;
        }

        .contact-info {
          .contact-name {
            color: $neutralDark;
            font-weight: bold;
            text-align: left;
          }

          h3 {
            margin: 5px 0 10px;

            @include breakpoint('mobile') {
              padding: 0;
            }
          }

          button {
            @include orangeButton;
            height: 40px;
            font-size: $fontRegular;
          }
        }
      }

      .contact-trinity-container {
        padding: 15px 0 35px;

        .contact-phone {
          font-size: 18px;
          color: $neutralDark;
          margin: 30px 0 10px;
          padding: 0 20px;

          i {
            margin-right: 10px;
          }
        }

        .office-hours {
          font-size: 18px;
          color: $neutralDark;
          padding: 0 20px;

          span {
            font-weight: bold;
          }
        }
      }
    }

    .workplace-img {
      background: url('../img/ContactPageImage.jpg') no-repeat;
      width: 670px;
      height: 620px;
      background-size: cover;
      background-position: center;

      @include breakpoint('tablet') {
        display: none;
      }
    }
  }
}
