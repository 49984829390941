//Font ==========================
$fontFamily: 'Montserrat', 'Arial', 'Helvetica', sans-serif;
$fontExtraSmall: 11px;
$fontSmall: 13px;
$fontRegular: 15px;
$fontLarge: 25px;
$fontExtraLarge: 50px;

//Box shadow ==========================
$box-shadow:0 2px 6px rgba(0,0,0,.1);

// Colors =============================
//Neutral
$neutralDarkest: #202228;
$neutralDark: #2d394a;
$neutralLight: #97b1ba;
$neutralLightest: #e6e8e9;

//Blue
$blueDark: #005f7c;
$blueMid: #0f8797;
$blueLight: #6abec6;

//Teal
$tealDark: #049b89;
$tealMid: #93d2cc;
$tealLight: #bee3df;

//Purple
$purpleDark: #3c3159;
$purpleMid: #625180;
$purpleLight: #9588ae;

//Yellow
$yellowDark: #f4bb6e;
$yellowMid: #f9d27d;
$yellowLight: #f4e3c1;

//Pink
$pinkDark: #ca3a6e;
$pinkMid: #ec928e;
$pinkLight: #f2c8c7;

//Orange
$orangeDark: #cb372a;
$orangeMid: #f77c30;
$orangeLight: #fba36c;

//Unofficial Colors ===================
//Orange
$orangeOutline: #DD6318;
$orangeHover: #D96015;
$orangeMisc:#E0740B;

//Whites
$white: #ffffff;

//Gradients
$warmGradient: linear-gradient(to right, $yellowDark, #ec928e, $purpleDark);

// Breakpoints  =======================
@mixin breakpoint($class) {
  @if $class == mobile {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $class == tablet {
    @media (max-width: 1094px) {
      @content;
    }
  }
}

// Buttons  ===========================
@mixin orangeButton {
  background-color: $orangeMid;
  min-height: 50px;
  height: 50px;
  width: 185px;
  text-align: center;
  color: $white;
  text-transform: uppercase;
  font: 700 $fontRegular $fontFamily;
  cursor: pointer;

  &:hover {
    background-color: $orangeHover;
  }
}

@mixin hollowOrangeButton {
  background-color: $white;
  border: 2px solid $orangeMid;
  min-height: 50px;
  height: 50px;
  width: 185px;
  text-align: center;
  color: $orangeMid;
  text-transform: uppercase;
  font: 700 $fontRegular $fontFamily;
  cursor: pointer;

  &:hover {
    background-color: $neutralLightest;
  }
}