@import './mixins.scss';

.account.view {
  .login-btn {
    display: block;
    @include orangeButton;
    text-decoration: none;
    line-height: 50px;
    margin: 15px 0 0;
    width: 100%;
  }

  h3 {
    font-weight: 700;
  }

  .auth {
    margin: 20px 0 5px;

    h3 {
      font-weight: 400;
      display: inline-block;
    }
  }

  .edit-link {
    color: $neutralLight;
    display: inline-block;
    text-decoration: underline;
    margin-left: 15px;
    font-size: $fontSmall;
    cursor: pointer;

    &:hover {
      color: $orangeMid;
    }

    &.edit {
      text-decoration: none;
    }
  }

  .connection-status {
    font-size: $fontSmall;

    &.not-connected {
      color: red;
    }

    &.connected {
      color: $blueMid;
    }
  }

  @include breakpoint('tablet') {
    .card.one-quarter {
      width: 100%;
      max-width: calc(100% - 30px);
    }
  }

  @include breakpoint('mobile') {
    .user-img {
      display: none;
    }
  }
}
