@import './mixins.scss';

.user-profile {
  display: flex;
  align-items: center;
  height: 100%;


  h3 {
    display: inline-block;
    margin: 5px 5px 5px 0;
  }

  p {
    display: inline-block;
  }

  .user-img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    cursor: pointer;
  }

  .user-image-container {
    position: relative;
    width: 64px;
    height: 64px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
  }

  .user-image-container .user-img-after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 33%;
    background: rgba(75, 75, 75, .75);
    text-align: center;
    cursor: pointer;
  }

  .user-image-container:hover .user-img-after {
    background: rgba(25, 25, 25, .75);
  }
}
