@import './mixins.scss';

.hours.view {
  .hours-data {
    .hours-chart {
      .key {
        padding: 0 0 20px;
        border-bottom: 0;
      }
    }
  }

  .card.one-third {
    @include breakpoint('tablet') {
      width: 100%;
      max-width: calc(100% - 30px);
    }
  }
}
