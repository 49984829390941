@import './mixins.scss';

.roadmap {
  .card {
    width: 100%;
  }

  .table {
    color: $neutralDark;

    .table-cell:first-of-type {
      font-weight: 700;
    }

    .progress-container {
      width: 100px;
    }
  }

  .view-all-link {
    margin: 30px 0 0;
  }
}
