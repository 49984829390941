@import './mixins.scss';

.add-user {
  background-color: $tealLight;
  width: 100%;

  text-align: center;
}

.card {
  background-color: aliceblue;
}
