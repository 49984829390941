@import './mixins.scss';

.filter {
  color: $neutralDark;
  display: flex;
  align-items: center;
  margin:10px 10px 15px;

  h3 {
    font-size: $fontRegular;
  }

  .dropdown {
    font-size: $fontSmall;
    position: relative;
    background-color: $white;
    box-shadow: $box-shadow;
    padding:5px 15px;
    margin:0 0 0 20px;
    min-width: 165px;
    border-radius: 2px;

    .dropdown-title {
      font-size: $fontRegular;
      cursor: pointer;

      &:after {
        content: '';
        border: solid $neutralDark;
        border-width: 0 2px 2px 0;
        padding: 3px;
        transform: rotate(45deg);
        transition: .15s;
        float: right;
        margin-top: 4px;
      }
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: $white;
      box-shadow: $box-shadow;
      width:100%;
      z-index: 1;
      top:100%;
      left:0;
      padding:0 15px;

      .dropdown-option{
        line-height: 26px;
        cursor: pointer;
        position: relative;
        margin:15px 0;

        &:before {
          content: '';
          display: inline-block;
          background: url('../img/CheckBox.svg') no-repeat;
          width: 19px;
          height: 19px;
          vertical-align: middle;
          margin-right: 15px;
        }

        &:after{
          content:'';
          width:26px;
          height: 26px;
          display:inline-block;
          background-position: center;
          background-repeat: no-repeat;
          border-radius: 50%;
          background-size: contain;
          vertical-align: middle;
          position: absolute;
          left:100px;
        }

        &.active-test::after{
          background-image: url('../img/test-status-icons/RunningTest.svg');
        }

        &.hosted-test::after{
          background-image: url('../img/test-status-icons/HostedTest.svg');
        }

        &.paused-test::after{
          background-image: url('../img/test-status-icons/PausedTest.svg');
        }

        &.past-test::after{
          background-image: url('../img/test-status-icons/PastTest.svg');
        }

        &.selected{
          font-weight: bold;

          &::before {
            background: url('../img/CheckBoxSelected.svg') no-repeat;
          }
        }
      }
    }

    &.open {
      .dropdown-title:after {
        transform: rotate(225deg);
        margin-top: 8px;
      }

      .dropdown-content {
        display: block;
      }
    }
  }

  @include breakpoint('mobile') {
    flex-wrap: wrap;
    width:100%;
    justify-content: space-between;

    h3{
      width:100%;
    }

    .dropdown{
      margin:20px 0 0;
      width:48%;
    }
  }
}
