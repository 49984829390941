@import './mixins.scss';

.calendar{
  color:$neutralDark;
  font-size: 12px;

  .month{
    display: flex;
    align-items: center;
    margin:0 0 25px;

    .current-month{
      margin:0 20px;
      font-size: 18px;
    }

    .prev-month,
    .next-month{
      width:16px;
      text-align: center;
      cursor: pointer;

      span{
        border: solid;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        margin:0 auto;
      }
    }

    .prev-month{
      span{
        transform:rotate(135deg);
      }
    }

    .next-month{
      span{
        transform:rotate(-45deg);
      }
    }
  }

  .weekdays{
    border-bottom: 1px solid #97B1BA80;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    
    .day{
      padding:10px 0;
    }
  }

  .week{
    display: flex;
    border-bottom: 1px solid #97B1BA80;
    
    &:last-of-type{
      border-bottom: transparent;
    }

    .day{
      min-height: 100px;
    }
  }

  .day{
    border-right: 1px solid #97B1BA80;
    flex:1 1 0%;
    text-align: center;
    padding:5px 0;

    &:last-of-type{
      border-right: transparent;
    }

    span{
      display:block;
      border-radius: 50%;
      width:27px;
      height:27px;
      line-height: 27px;
      margin:0 auto;
    }

    &.today{
      span{
        background-color: $orangeMid;
        color:$white;
      }
    }
  }
}