.negative {
  color: #D24B20;
}
.add-adjustment {
  position: relative;
  border: 2px solid #A2BAC3;
  color: #A2BAC3;
  font-size: 12px;
  text-transform: uppercase;
  width: 65px;
  height: 26px;
  // text-align: center;
  line-height: 23px;
  display: block;
  padding-left: 10px;
  font-weight: bold;

  &:after {
    position: absolute;
    right: 10px;
    content: '+';
    font-size: 16px;
    pointer-events: none;
  }

  &:hover {
    cursor: pointer;
  }
}
.adjustment:hover {
  cursor: help;
}
.table-row .table-cell {
  text-align: center;

  &:first-child,
  &:last-child {
    text-align: left;
  }
}

.table-head {
  position: sticky;
}

.edit-message {
  font-size: 11px;
  color: #f77c30;
  font-weight: bold;
}