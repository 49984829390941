@import './mixins.scss';

.test-status.view {
  .left-side {
    width: 25%;
    display: flex;
    flex-direction: column;

    @include breakpoint('tablet') {
      flex-direction: row;
      width: 100%;
    }

    @include breakpoint('mobile') {
      flex-direction: column;
      width: calc(100% - 30px);

      .card {
        width: 100%;
      }
    }
  }

  .right-side {
    display: flex;
    flex-wrap: wrap;
    width: 75%;

    @include breakpoint('tablet') {
      width: 100%;

      .one-third {
        width: calc(33.33% - 30px);
      }
    }

    @include breakpoint('mobile') {
      .one-third {
        width: calc(100% - 30px);
      }
    }

    .view-all-link {
      margin: 30px 0 0;
    }
  }
}
