@import './mixins.scss';

.dashboard {
  .scorecard-wrapper {
    width: 100%;
    display: flex;

    @include breakpoint('tablet') {
      width: 50%;
      flex-direction: column;

      .one-third {
        width: 100%;
        max-width: calc(100% - 30px);
      }
    }

    @include breakpoint('mobile') {
      width: 100%;
    }
  }

  .table {
    margin: 30px 0 0;
  }

  .hours-data {
    h3,
    .hours-carried-over,
    .hours-total {
      display: none;
    }

    .hours-info {
      background-color: $white;
      margin: 0;
      padding: 0;
      color: $neutralDark;

      .hours-contracted {
        border-bottom: 1px solid $neutralLightest;
      }

      span {
        color: $tealDark;
      }
    }
  }

  .view-all-link {
    padding: 20px 0 0;
  }

  .one-third .view-all-link {
    border-top: 1px solid $neutralLightest;
  }
}
