@import './mixins.scss';

.overlay {
  display: none;
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.5);
  top: 0;
  z-index: 1;

  @include breakpoint('mobile') {
    &.menu-open {
      display: block;
    }
  }
}

.sidebar {
  height: 100vh;
  width: 240px;
  background-color: $neutralDark;
  font-size: $fontRegular;
  z-index: 1;
  position: fixed;
  top: 0;
  transition: .25s;

  .sidebar-logo-parent {
    background-color: #283442;
    padding: 0 20px;
    display: flex;
    align-items: center;
    height: 70px;

    .sidebar-logo {
      background: url('../img/trinity-branding/trinity-white@2x.png') no-repeat;
      background-size: contain;
      width: 140px;
      height: 32px;
      display: block;
    }
  }

  .sidebar-link-parent {
    border-left: 8px solid transparent;
    height: 55px;
    position: relative;

    &:hover {
      background-color: #283442;
    }

    .sidebar-link {
      color: $white;
      text-decoration: none;
      padding: 0 20px;
      display: block;
      height: 55px;
      line-height: 55px;
      transition: .25s;

      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 15px;
        width: 20px;
      }

      &.dashboard-link:before {
        background-image: url('../img/menu-icons/chart-bar-solid.svg');
        height: 16px;
      }

      &.test-status-link:before {
        background-image: url('../img/menu-icons/flask-solid.svg');
        height: 16px;
      }

      &.roadmap-link:before {
        background-image: url('../img/menu-icons/Roadmap.svg');
        height: 14px;
      }

      &.visualizer-link:before {
        background-image: url('../img/menu-icons/Visualizer.svg');
        height: 20px;
      }

      &.hours-link:before {
        background-image: url('../img/menu-icons/Hours.svg');
        height: 17px;
      }

      &.contact-link:before {
        background-image: url('../img/menu-icons/Contact.svg');
        height: 14px;
      }

      &.calendar-link:before {
        background-image: url('../img/menu-icons/calendar-alt-regular.svg');
        height: 18px;
      }
    }

    &.active {
      background-color: $yellowLight;
      border-left: 8px solid $orangeMid;
      width:250px;
      transition: width .25s;

      &:after{
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        display: block;
        top: 100%;
        right: 0;
        border-top: 5px solid $orangeMid;
        border-left: 5px solid $orangeMid;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
      }

      .sidebar-link{
        color:$neutralDark;
      }

      .dashboard-link:before {
        background-image: url('../img/menu-icons/chart-bar-solid-active.svg');
      }

      .test-status-link:before {
        background-image: url('../img/menu-icons/flask-solid-active.svg');
      }

      .roadmap-link:before {
        background-image: url('../img/menu-icons/Roadmap-Active.svg');
      }

      .visualizer-link:before {
        background-image: url('../img/menu-icons/Visualizer-Active.svg');
      }

      .hours-link:before {
        background-image: url('../img/menu-icons/Hours-Active.svg');
      }

      .contact-link:before {
        background-image: url('../img/menu-icons/Contact-Active.svg');
      }

      .calendar-link:before {
        background-image: url('../img/menu-icons/calendar-alt-regular-active.svg');
      }

      @include breakpoint('tablet') {
        width:80px;
      }
    }
  }

  .sidebar-toggle {
    text-align: right;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    cursor: pointer;

    @include breakpoint('mobile') {
      display: none;
    }

    &:after {
      content: '';
      border: solid $neutralLight;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(135deg);
      margin: 0 15px 0 10px;
    }
  }

  @include breakpoint('mobile') {
    display: none;

    &.menu-open {
      display: block;
    }
  }

  @include breakpoint('tablet') {
    width: 70px;

    .sidebar-logo-parent {
      .sidebar-logo {
        background: url('../img/trinity-branding/Trinity-Shield.png') no-repeat;
        background-size: contain;
      }
    }

    .sidebar-link-parent .sidebar-link {
      font-size: 0;
    }

    .sidebar-toggle {
      display: none;
    }
  }
}

.collapsed {
  .sidebar {
    width: 70px;

    .sidebar-logo-parent {
      .sidebar-logo {
        background: url('../img/trinity-branding/Trinity-Shield.png') no-repeat;
        background-size: contain;
      }
    }

    .sidebar-link-parent {
      &.active{
        width:80px;
      }

      .sidebar-link {
        font-size: 0;
      }
    }
  }

  .sidebar-toggle {
    &:after {
      transform: rotate(-45deg);
    }
  }
}

